import {  Input,Spin,message } from "antd";
import React from "react";
import axios from "./CustomAxios";
import Etiket from "./etiket";
import { LoadingOutlined } from "@ant-design/icons";
import { WritePrinter } from "./WritePrinter";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Gecmis({ setBreadcrumb }) {
  const [loading, setLoading] = React.useState(true);
  const etiketRef = React.useRef(null);
  const [siparisDetay, setSiparisDetay] = React.useState(null);
  const [siparis, setSiparis] = React.useState(null);
  const [siparisler, setSiparisler] = React.useState([]);

  const getSiparisler = () => {
    axios.get("gecmis").then((result) => {
      setSiparisler(result.data);
      setLoading(false);
    });
  };

  const yazdir = () => {
    let printer_name = siparisDetay?.kargo === 1 ? "zebra1" : "zebra2";

    if (siparisDetay.aras_zpl) {
      message.success({
        content: "Başarılı",
        key: "updatable",
        duration: 2,
      });
    
      WritePrinter(siparisDetay.aras_zpl, printer_name);
     // window.writeToSelectedPrinter(siparisDetay.aras_zpl, printer_name);
    } else {
      let a = window.open("", "", "height=500, width=500");
      a.document.write("<html>");
      a.document.write("<body >");
      a.document.write(`
          <style>
              body{
                  margin: 0px;
                  padding: 0px;
                  font-family: Arial, Helvetica, sans-serif;
              }
              .genel {                    
                  font-size: 11px !important; 
              }
              .baslik {
                  font-weight:bold; margin-bottom: 7px;
              }
              .barkod {
                  font-weight: bold !important;
              }
              .deger {
                  font-size: 26px; font-weight: bold !important;
              }
              .adet {
                  font-size: 75px;
              }
              .genel-text {
                  font-size: 11px !important;
              }
              .cerceve {
                  height: auto;
                  width: 390px;
                  border-radius: 10px;
                  border: 2px solid #000;
                  padding: 5px;
              }
              .baslik {
                  height: auto;
                  width: 395px;
                  padding: 3px;
              }
              .footer {
                  font-size: 10px;
                  font-style: normal;
                  margin-top: 2px;
              }
              .sol {
                  font-size: 18px;
                  font-style: normal;
                  font-weight: bolder;
              }
              .adres    {
                  text-align: justify;
              }
            h1 {
              font-size: 22px !important;
              font-style: normal !important;
              font-weight: bolder !important;
            }
          </style>`);
      a.document.write(etiketRef.current?.innerHTML);
      a.document.write("</body></html>");
      a.document.close();
      a.onload = function () {
        a.print();
      };
      setTimeout(() => {
        a.close();
      }, 200);
    }
  };


  const siparisOkut = async (e) => {
    if (e.key === "Enter") {
      const key = "updatable";
      e.preventDefault();
      message.loading({ content: "Yükleniyor...", key });

      axios
        .post("gecmisSiparis", { 
          siparis_id: e.target.value,
        })
        .then((result) => {
          if(result.data){
            message.success({ content: "başarılı", key, duration: 2 });
            setSiparisDetay(result.data);
          }else{
            message.error({ content:  "sipariş hazır değil", key, duration: 2 });
          }
        })
        .catch((result) => {
          message.error({ content:  "başarısız", key, duration: 2 });
        });
    }
  };

  React.useEffect(() => {
    setBreadcrumb("Geçmiş");
    getSiparisler();
  }, [setBreadcrumb]);

  React.useEffect(() => {
    if (siparisDetay === null) return;
    // console.log(siparisDetay);
    yazdir();
  }, [siparisDetay]);

  return (
    <React.Fragment>
      {loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <React.Fragment>
          <label className="col-12">
            Barkod almak için sipariş kodu giriniz
          <Input
      placeholder="Sipariş Kodu Giriniz"
      size="large"
      onKeyDown={siparisOkut}
      value={siparis}
      onChange={(e) => setSiparis(e.target.value)}
    />
          </label>
          <br/>
          <br/>
          <br/>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" width="100">Kargo Firması</th>
                <th scope="col" width="100">
                  Sipariş No
                </th>
                <th scope="col">Adı Soyadı</th>
                <th scope="col">Adet</th>
                <th scope="col" width="200">
                  Genel Toplam
                </th>
                <th scope="col" width="100">
                  Yazdır
                </th>
              </tr>
            </thead>
            <tbody>
              {siparisler.map((item, key) => {
                return (
                  <tr key={key}>
                    <td><img
            key={item.id}
            width="70"
            height="58"
            style={{ objectFit: "contain" }}
            alt="cargo icon"
            src={
              item?.kargo === 1
                ? "https://cdn.modamizbir.com/assets/images/arasicon.png"
                : item?.kargo === 2
                ? "https://cdn.modamizbir.com/assets/images/fedexicon.png"
                : "https://cdn.modamizbir.com/assets/images/kargoisticon.png"
            }
          /></td>
                    <td>{item.id}</td>
                    <td>
                      {item.ad} {item.soyad}
                    </td>
                    <td>{item.sepeturunler.reduce((a, b) => a + b.adet, 0)}</td>
                    <td>
                      <b>{item.genel_toplam} TL</b>
                    </td>
                    <td>
                      <button
                        onClick={() => setSiparisDetay(item)}
                        className="btn btn-success btn-sm"
                      >
                        Etiket Yazdır
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="row d-none">
            <div className="col" id="etiket" ref={etiketRef}>
              <Etiket
                id={siparisDetay?.sepeturunler[0]?.siparis_id}
                ad={siparisDetay?.ad}
                soyad={siparisDetay?.soyad}
                mahalle_adi={siparisDetay?.adres_mahalle.mahalle_adi}
                adres={siparisDetay?.adres}
                il_adi={siparisDetay?.adres_il.il_adi}
                ilce_adi={siparisDetay?.adres_ilce.ilce_adi}
                telefon={siparisDetay?.telefon}
                odeme_turu={siparisDetay?.odeme_turu}
                toplam_urun_sayisi={siparisDetay?.sepeturunler.reduce(
                  (a, b) => a + b.adet,
                  0
                )}
                kargo_tutari={siparisDetay?.kargo_tutari}
                komisyon_tutari={siparisDetay?.komisyon_tutari}
                kapida_odeme_tutari={siparisDetay?.kapida_odeme_tutari}
                genel_toplam={siparisDetay?.genel_toplam}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
