import React from "react";
import { message, Spin } from "antd";
import axios from "./CustomAxios";
import "./girisyap.css";

export default function Girisyap() {
  const [loading, setLoading] = React.useState(false);

  const [telefon, setTelefon] = React.useState("");
  const [sifre, setSifre] = React.useState("");

  const girisYap = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.post("login", { gsm: telefon, password: sifre }).then((res) => {
      if (res.data.status === 1) {
        message.success("Başarıyla giriş yaptınız.", 3);
        localStorage.setItem("token", res.data.token);
        window.location.reload();
        // setIsLogin(true);
      } else if (res.data.status === 3) {
        message.error("Hesap bulunamadı.", 3);
      } else if (res.data.status === 0) {
        message.error("Şifrenizi hatalı girdiniz.", 3);
      }
      setLoading(false);
    });
  };

  return (
    <div className="login-out">
      <div className="login-container">
        <form onSubmit={girisYap}>
          <h2 className="title">Giriş Yap</h2>
          <div className="email-login">
            <label htmlFor="email">
              <b>Telefon</b>
            </label>
            <input
              type="text"
              placeholder="Telefonunuzu giriniz."
              value={telefon}
              onChange={(e) => setTelefon(e.target.value)}
              required
            />
            <label htmlFor="psw">
              <b>Şifre</b>
            </label>
            <input
              type="password"
              placeholder="Şifrenizi giriniz."
              value={sifre}
              onChange={(e) => setSifre(e.target.value)}
              required
            />
          </div>
          <button className="cta-btn">
            {loading ? <Spin /> : "Giriş Yap"}
          </button>
        </form>
      </div>
    </div>
  );
}
