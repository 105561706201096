import React from "react";
import JsBarcode from "jsbarcode";

export default function Etiket({
  personel,
  id,
  ad,
  soyad,
  mahalle_adi,
  adres,
  il_adi,
  ilce_adi,
  telefon,
  odeme_turu,
  toplam_urun_sayisi,
  kargo_tutari,
  komisyon_tutari,
  kapida_odeme_tutari,
  genel_toplam,
  siparis_tutari,
  kdv_tutari,
}) {
  React.useEffect(() => {
    JsBarcode("#barcode", "MB" + id, {
      width: 1,
      height: 41,
      format: "CODE128",
      fontSize: 12,
    });
  }, [id]);

 

  
  return (
    
    <>
     { !personel ?
      <div className="genel">
        <div className="baslik">
          <table width="385" border="0" cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th
                  width="37"
                  align="left"
                  valign="middle"
                  className="sol"
                  scope="col"
                >
                  <img
                    src="https://panel.pembebeyaz.com/assets/img/phone.svg"
                    width="30px"
                    alt="phone"
                  />
                </th>
                <th
                  width="193"
                  align="left"
                  valign="middle"
                  className="sol"
                  scope="col"
                >
                  0850 305 55 55
                </th>
                <th width="155" scope="col">
                  <img
                    src="https://panel.pembebeyaz.com/assets/img/logomb.svg"
                    width="155px"
                    alt="logo"
                  />
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="cerceve">
          <table width="385" border="0">
            <tbody>
              <tr>
                <td width="197">
                  <div className="genel-text">
                    <b>
                      {ad} {soyad}
                    </b>
                    <br />
                    {mahalle_adi} {String(adres).substr(0, 100)} {il_adi}/
                    {ilce_adi} {telefon}
                  </div>
                </td>
                <td width="150" height="41">
                  <svg width="5" id="barcode"></svg>
                </td>
              </tr>

              <tr>
                <td height="34" colSpan="2">
                  <hr
                    style={{
                      borderTop: "1px dashed black",
                      borderBottom: "none",
                    }}
                    // style="border-top: 1px dashed black; border-bottom: none;"
                  />
                  <table width="385" border="0">
                    <tbody>
                      <tr>
                        <td width="170" height="86" align="center">
                          {odeme_turu === 1 && <h1>Kapıda Ödeme</h1>}

                          {odeme_turu === 2 && <h1>Kredi Kartı</h1>}
                        </td>
                        <td width="205" align="center" className="">
                          Ürün Sayısı
                          <br />
                          <strong className="adet">{toplam_urun_sayisi}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="385" border="0">
                    <tbody>
                      <tr>
                        <td width="278" height="10" colSpan="3" align="right">
                          <span className="genel-text">KARGO TUTARI : </span>
                        </td>
                        <td width="10" align="center"></td>
                        <td width="83" align="right">
                          <span className="genel-text"> {kargo_tutari} TL</span>
                        </td>
                      </tr>
                      {odeme_turu === 1 && (
                        <tr>
                          <td width="278" height="10" colSpan="3" align="right">
                            <span className="genel-text">
                              KAPIDA ÖDEME TUTARI :{" "}
                            </span>
                          </td>
                          <td width="10" align="center"></td>
                          <td width="83" align="right">
                            <span className="genel-text">
                              {kapida_odeme_tutari} TL
                            </span>
                          </td>
                        </tr>
                      )}

                      {odeme_turu === 2 && (
                        <tr>
                          <td width="278" height="10" colSpan="3" align="right">
                            <span className="genel-text">
                              BANKA KOMİSYONU :{" "}
                            </span>
                          </td>
                          <td width="10" align="center"></td>
                          <td width="83" align="right">
                            <span className="genel-text">
                              {komisyon_tutari} TL
                            </span>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td height="10" colSpan="3" align="right">
                          <span className="genel-text">
                            TOPLAM (KDV DAHİL) :{" "}
                          </span>
                        </td>
                        <td align="center"></td>
                        <td align="right">
                          <span className="genel-text">{genel_toplam} TL </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="15" colSpan="2" align="right"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <table
          width="400"
          border="0"
          cellPadding="0"
          cellSpacing="0"
          className="footer"
        >
          <tbody>
            <tr>
              <th scope="col">
                <img
                  src="https://panel.pembebeyaz.com/assets/img/logomb.svg"
                  alt=""
                  width="130"
                  height="17"
                />
              </th>
              <th align="left" valign="top" scope="col">
                <div className="genel-text">
                  MODAMIZBİR TEKSTİL SAN.VE.TİC.LTD.ŞTİ.
                  <br />
                  Panayır Mh. İstanbul Yolu Cd. No:421 Osmangazi/BURSA
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      :
      <div className="genel">
        <div className="baslik">
          <table width="385" border="0" cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th
                  width="37"
                  align="left"
                  valign="middle"
                  className="sol"
                  scope="col"
                >
                </th>
                <th
                  width="193"
                  align="left"
                  valign="middle"
                  className="sol"
                  scope="col"
                >
                
                </th>
                <th width="155" scope="col">
                 
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="cerceve-personel">
          
        <svg width="5" id="barcode" className="barcode-personel"></svg>
          <table width="385" border="0">
            <tbody>
              <tr>
                <td width="197" height="41">
                    
                  <br />
                    <h1 style={{textAlign:'center'}}>PERSONEL SİPARİŞ BARKODU</h1>
                  
                    <br />
                </td>
                <td>
                  <svg width="5" id="barcode"></svg>
                </td>
              </tr>

              <tr>
                <td height="34" colSpan="2">
                  
                  <table width="385" border="0">
                    <tbody>
                      <tr>
                        <td width="170" height="86" align="center">
                          
                    <h1>
                      {ad} {soyad}
                    </h1>
                          {odeme_turu === 1 && 'Kapıda Ödeme'}

                          {odeme_turu === 2 && 'Kredi Kartı'}
                        </td>
                        <td width="205" align="center" className="">
                          Ürün Sayısı
                          <br />
                          <strong className="adet">{toplam_urun_sayisi}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table width="385" border="0">
                    <tbody>
                      <tr>
                      <td width="278" height="10" colSpan="3" align="right">
                          <span className="genel-text">KDV'SİZ TUTAR : </span>
                        </td>
                        <td width="10" align="center"></td>
                        <td width="83" align="right">
                          <span className="genel-text"> {siparis_tutari} TL</span>
                        </td>
                      </tr>
                      <tr>
                      <td width="278" height="10" colSpan="3" align="right">
                          <span className="genel-text">KDV TUTARI : </span>
                        </td>
                        <td width="10" align="center"></td>
                        <td width="83" align="right">
                          <span className="genel-text"> {kdv_tutari} TL</span>
                        </td>
                      </tr>
                      <tr>
                        <td width="278" height="10" colSpan="3" align="right">
                          <span className="genel-text">KARGO TUTARI : </span>
                        </td>
                        <td width="10" align="center"></td>
                        <td width="83" align="right">
                          <span className="genel-text"> {kargo_tutari} TL</span>
                        </td>
                      </tr>
                      {odeme_turu === 1 && (
                        <tr>
                          <td width="278" height="10" colSpan="3" align="right">
                            <span className="genel-text">
                              KAPIDA ÖDEME TUTARI :{" "}
                            </span>
                          </td>
                          <td width="10" align="center"></td>
                          <td width="83" align="right">
                            <span className="genel-text">
                              {kapida_odeme_tutari} TL
                            </span>
                          </td>
                        </tr>
                      )}

                      {/* {odeme_turu === 2 && (
                        <tr>
                          <td width="278" height="10" colSpan="3" align="right">
                            <span className="genel-text">
                              BANKA KOMİSYONU :{" "}
                            </span>
                          </td>
                          <td width="10" align="center"></td>
                          <td width="83" align="right">
                            <span className="genel-text">
                              {komisyon_tutari} TL
                            </span>
                          </td>
                        </tr>
                      )} */}
                      <tr>
                        <td height="10" colSpan="3" align="right">
                          <span className="genel-text">
                            TOPLAM (KDV DAHİL) :{" "}
                          </span>
                        </td>
                        <td align="center"></td>
                        <td align="right">
                          <span className="genel-text">{genel_toplam} TL </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height="15" colSpan="2" align="right"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <table
          width="400"
          border="0"
          cellPadding="0"
          cellSpacing="0"
          className="footer"
        >
          <tbody>
            <tr>
              <th align="left" valign="top" scope="col">
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    }
    </>
  );
  }
