import React from "react";
import { Modal, Button } from "react-bootstrap";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "70%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ModalComponent(props) {
  function afterOpenModal(e) {
    props.onAfterOpen(e, "After Modal Opened");
  }

  function onModalClose(event) {
    let data = { name: "example", type: "closed from child" };
    props.onCloseModal(event, data);
  }

  return (
    <Modal
      size="lg"
      show={props.IsModalOpened}
      onHide={(e) => onModalClose(e)}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header>
        <Modal.Title id="example-custom-modal-styling-title">
          Geçmiş Raf Hareketleri
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <table className="table">
          <thead>
            <th>Raf</th>
            <th>Adet</th>
            <th>Oluşturma Tarihi</th>
            <th>Güncelleme Tarihi</th>
            <th>Silinme Tarihi</th>
          </thead>
          <tbody>
            {props.dynData?.data.map((item) => (
              <tr>
                <td>{item.raf}</td>
                <td>{item.adet}</td>
                <td>{item.created_at}</td>
                <td>{item.updated_at}</td>
                <td>{item.deleted_at}</td>
              </tr>
            ))}
            {!props.dynData ||
              ((props.dynData?.data).length == 0 && (
                <>
                  <tr>
                    <td className="text-center" colSpan={6}>
                      Hiç kayıt yok..
                    </td>
                  </tr>
                </>
              ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={(e) => onModalClose(e)}>
          Kapat
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalComponent;
