import React from "react";
import { Row, Col, Input, message, Spin } from "antd";
import { useParams, useHistory } from "react-router-dom";
import axios from "./CustomAxios";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const gridStyle = {
  textAlign: "center",
  height: 200,
  padding: "8px 8px",
  marginTop: "15px",
  borderRadius: "10px",
  // backgroundColor: '#ffd54f',
};
export default function EksikUrunSiparisOnaylama({ setBreadcrumb }) {
  const params = useParams();
  const history = useHistory();

  const [loading, setLoading] = React.useState(true);

  const [OnaylananSayi, setOnaylananSayi] = React.useState(0);
  const [ReddedilenSayi, setReddedilenSayi] = React.useState(0);

  //çekilen raflar
  const [raflar, setRaflar] = React.useState([]);


  const durumDegistir = (id, durum_id) => {
    axios
      .post("eksik-urun-siparis-durum-degistir", {
        id: id,
        durum_id: durum_id,
      })
      .then((res) => {
        setRaflar(raflar.filter((x) => x.id !== id));

        message.success("Başarılı");
      });
  };
  React.useEffect(() => {
    axios
      .get("siparisler", {
        params: {
          //kullanici_id: 4,
          sepet_id: 1900,
          eksik_urun_raporu:1
        },
      })
      .then((result) => {
        setRaflar(result.data.filter((x) => x.eksik_urun_durum === 1));

        // let temp = result.data.map((item) => {
        //   if (item.siparis === null) {
        //     let ttt = { ...item, aktif: false };
        //     ttt.siparis = { urunler: [{ adet: 0, ayrim_adet: 0 }] };
        //     return ttt;
        //   } else {
        //     return { ...item, aktif: true };
        //   }
        // });
        // setRaflar(temp);
        setLoading(false);
      });
  }, [params.id]);

  React.useEffect(() => {
    axios
      .get("eksik-urun-rapor")
      .then((result) => {
        console.log(result.data[0].Tip);
        result.data.map((value,key) => {
          if(value.Tip == 'Onaylanan'){
            setOnaylananSayi(value.sayi)
          }else if(value.Tip == 'Reddedilen'){
            setReddedilenSayi(value.sayi)
          }
        })
      });
  }, []);


  React.useEffect(() => {
    setBreadcrumb("Eksik Ürün Siparişler");
  }, [setBreadcrumb, params.id]);

  if (loading) return <Spin indicator={antIcon} />;

  return (
    <>
      <Row gutter={16}>
        <div>Bugün Onaylanan Sayı : {OnaylananSayi} / Bugün Reddedilen Sayı : {ReddedilenSayi}</div>
        {raflar.map((raf, key) => {
          return (
            <Col className="gutter-row" span={24} key={key}>
              <div
                style={{
                  ...gridStyle,
                  background: "gray",
                }}
                onClick={() =>
                  history.push("/eksik-urun-onaylama-detay/" + raf.siparis_id)
                }
              >
                <Row>
                  <Col span={12}>
                    <h1
                      style={{
                        fontSize: 40,
                        fontWeight: "bold",
                        color: "#fff",
                        textAlign: "left",
                        marginBottom: 5,
                        paddingLeft: 10,
                      }}
                    >
                      {raf.raf_adi}
                    </h1>
                    <h1
                      style={{
                        fontSize: 22,
                        fontWeight: "normal",
                        color: "#fff",
                        textAlign: "left",
                        paddingLeft: 10,
                      }}
                    >
                      {raf.siparis_id}
                    </h1>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      fontSize: 30,
                      color: "#fff",
                      textAlign: "right",
                    }}
                  >
                    <Row>
                      <Col span={24} style={{ paddingRight: 10 }}></Col>
                      <Col span={24}>
                        {raf.siparis_detay !== null && (
                          <img
                            alt="kargo icon"
                            width="50"
                            height="50"
                            style={{ objectFit: "contain" }}
                            src={
                              raf.siparis_detay?.kargo === 1
                                ? "https://cdn.modamizbir.com/assets/images/arasicon.png"
                                : raf.siparis_detay?.kargo === 2
                                ? "https://cdn.modamizbir.com/assets/images/fedexicon.png"
                                : raf.siparis_detay?.kargo === 3
                                ? "https://cdn.modamizbir.com/assets/images/kargoisticon.png"
                                : raf.siparis_detay?.kargo === 4
                                ? "https://cdn.modamizbir.com/assets/images/kargomsende.png"
                                : null
                            }
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: 16,
                      color: "#fff",
                      textAlign: "left",
                      paddingLeft: 10,
                    }}
                  >
                    {"Müşteri: " +
                      raf.siparis_detay.ad +
                      " " +
                      raf.siparis_detay.soyad}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: 16,
                      color: "#fff",
                      textAlign: "left",
                      paddingLeft: 10,
                    }}
                  >
                    {"Telefon: " + raf.siparis_detay.telefon}
                  </Col>
                  <Col
                    span={24}
                    style={{
                      fontSize: 16,
                      color: "#fff",
                      textAlign: "left",
                      marginTop: 10,
                    }}
                  >
                    <button
                      className="btn btn-success btn-sm w-50"
                      onClick={() => durumDegistir(raf.id, 2)}
                    >
                      Onayla
                    </button>
                    <button
                      className="btn btn-danger btn-sm w-50"
                      onClick={() => durumDegistir(raf.id, 3)}
                    >
                      Reddet
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
