import React, { useEffect, useState } from "react";
import { Input, message } from "antd";
import axios from "./CustomAxios";
import { useHistory } from "react-router-dom";

export default function Anasayfa({ setBreadcrumb }) {
  const history = useHistory();
  const [sepet, setSepet] = React.useState("");

  const [paketledigimUrunAdeti, setPaketledigimUrunAdeti] = useState(0);

  const sepetokut = async (e) => {
    if (e.key === "Enter") {
      const key = "updatable";
      e.preventDefault();
      message.loading({ content: "Yükleniyor...", key });

      axios
        .post("sepetokut", {
          sepet: e.target.value,
          // istasyon_id: 1,
          // kullanici_id: 4
        })
        .then((result) => {
          message.success({ content: result.data.message, key, duration: 2 });
          //console.log(result.data)
          setSepet("");
          history.push("/sepet/" + result.data.sepet);
        })
        .catch((result) => {
          message.error({ content: result.message, key, duration: 2 });
        });
    }
  };

  const getPaketledigimUrunAdeti = () => {
    axios.get("/terminal/raporum", { params: { type: 2 } }).then((res) => {
      setPaketledigimUrunAdeti(res.data);
    });
  };

  React.useEffect(() => {
    setBreadcrumb("Anasayfa");
    getPaketledigimUrunAdeti();
    
    // window.writeToSelectedPrinter("^XA^FO200,200^A0N36,36^FDTest Label^FS^XZ");
  }, [setBreadcrumb]);

  // useEffect(() => {
  //    WriteForLinux("^XA^FO200,200^A0N36,36^FDTest Label^FS^XZ");
  // },[])

  return (
    <>
      <Input
        placeholder="Sepet Okutun"
        size="large"
        onKeyDown={sepetokut}
        value={sepet}
        onChange={(e) => setSepet(e.target.value)}
      />

      <div className="row mt-3">
        <div className="col-12">
          Günlük Paketlediğim Ürün Adeti : {paketledigimUrunAdeti}
        </div>
      </div>
    </>
  );
}
