import React from "react";
import { Input, message, PageHeader, Modal, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import axios from "./CustomAxios";

const Barcode = require("react-barcode");

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function EksikUrunSiparisOnaylamaDetay({ setBreadcrumb }) {
  //Linkten idyi alır
  const history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = React.useState(true);

  const [data, setData] = React.useState(null);
  // const [userData, setUserData] = React.useState(null);

  const [siparisDurum, setSiparisDurum] = React.useState(0);

  const [smsDurum,setSmsDurum] = React.useState(0);
  const [smsMesaj,setSmsMesaj] = React.useState('');

  const [urunler, setUrunler] = React.useState([]);

  // const [toplamUrun, setToplamUrun] = React.useState(0);

  const siparis = React.useCallback(() => {
    axios.get("siparisdetay/" + id).then((result) => {
      console.log(result.data);
      // setAyar(result.data.ayar);
      setData(result.data.siparis);
      setUrunler(result.data.siparis.urunler);
      setLoading(false);

      console.log(result.data.siparis);

      // setSiparisDetay(result.data.siparis.siparis_detay);
      // setAdresMahalle(result.data.siparis.siparis_detay.adres_mahalle);
      // setAdresIl(result.data.siparis.siparis_detay.adres_il);
      // setAdresIlce(result.data.siparis.siparis_detay.adres_ilce);

      // setSiparisDurum(result.data.siparis.siparis_detay.durum);

      // setPersonelSiparisi(
      //   result.data.siparis.siparis_detay?.uye?.personel ?? 0
      // );

      // let genelToplam = parseFloat(
      //   result.data.siparis.siparis_detay.genel_toplam
      // );

      // let genelToplam2 = parseFloat(
      //   result.data.siparis.urunler.reduce(
      //     (a, b) =>
      //       a +
      //       parseFloat(b.sepeturun?.ara_toplam) +
      //       parseFloat(b.sepeturun?.kdv_toplam),
      //     0
      //   ) +
      //     parseFloat(result.data.siparis.siparis_detay.kargo_tutari) +
      //     parseFloat(result.data.siparis.siparis_detay.kapida_odeme_tutari)
      // );

      // let fark = 4.0;
      // if (Math.abs(genelToplam2 - genelToplam) > fark) {
      //   console.log("fark");
      //   Modal.error({
      //     title: "Uyarı",
      //     content:
      //       "Sepet Toplamı : " +
      //       genelToplam2 +
      //       " Sipariş Toplamı " +
      //       genelToplam +
      //       "     Fiyat farklılığı vardır. Muhasebeye bilgi veriniz.",
      //   });
      //   setYazdirVisible(false);
      // } else {
      //   setYazdirVisible(true);
      // }

      // setLoading(false);
    });
  }, [id]);

  const renkKontrol = (urun) => {
    if (urun.kontrol_adet === urun.adet) {
      return "#00ff80";
    } else if (urun.kontrol_adet > 0) {
      return "#efff00";
    }
  };

  const escFunction = React.useCallback(
    (event) => {
      if (event.keyCode === 27) {
        history.goBack();
      }
    },
    [history]
  );

  // React.useEffect(() => {
  //   setToplamUrun(urunler.reduce((a, b) => a + b.adet, 0));
  // }, [urunler]);

  const durumDegistir = (id, durum_id) => {
    axios
      .post("eksik-urun-siparis-durum-degistir", {
        id: id,
        durum_id: durum_id,
      })
      .then((res) => {
        // setRaflar(raflar.filter((x) => x.id !== id));

        message.success("Başarılı");

        history.goBack();
      });
  };
  
  const smsGonder = () => {
    if(smsMesaj != ''){
      axios
      .post("smsgonder", {
        phone: data.siparis_detay.telefon,
        message: smsMesaj,
      })
      .then((res) => {
        // setRaflar(raflar.filter((x) => x.id !== id));
        message.success("Başarılı");
      });
    }
  }

  React.useEffect(() => {
    setBreadcrumb("Sipariş Detay (" + id + ")");
  }, [setBreadcrumb, id]);

  React.useEffect(() => {
    siparis();
  }, [siparis]);

  React.useEffect(() => {
    window.addEventListener("keydown", escFunction, false);
    return () => window.removeEventListener("keydown", escFunction, false);
  }, [escFunction]);

  React.useEffect(() => {
    if (siparisDurum === 4) {
      Modal.error({
        title: "Uyarı",
        content: "Sipariş iptal edilmiş",
      });
    }
  }, [siparisDurum]);

  if (loading) return <Spin indicator={antIcon} />;

  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={"Sipariş " + id}
        style={{
          // border: "1px solid #e0e0e0",
          borderRadius: 3,
          background: "#eee",
        }}
      />
      <br />

      <div className="row" style={{ flexWrap: "wrap" }}>
        <table className="table table-striped table-striped text-center">
          <thead>
            <tr>
              <th scope="col">Resim</th>

              <th scope="col">Okutulan</th>
              <th scope="col">Adet</th>
            </tr>
          </thead>
          <tbody>
            {urunler.map((item, key) => {
              if (item.sepeturun) {
                return (
                  <tr
                    key={key}
                    style={
                      item.kontrol_adet !== 0
                        ? { background: renkKontrol(item) }
                        : {}
                    }
                  >
                    <td>
                      <img height="150" src={item.resim} alt="img" />
                    </td>

                    <td>
                      <h2>
                        {item.sepeturun.varyasyon_adi + " - "}
                        <Barcode
                          height="100"
                          background="transparent"
                          value={item.varyasyon_id ?? item.barkod}
                        />
                      </h2>
                      {item.sepeturun.eksik === 1 && <h2>Eksik Ürün</h2>}
                    </td>
                    <td>
                      <h2 className="mb-4">
                        {item.adet + " / " + item.kontrol_adet}
                      </h2>
                      <h4>{item.sepeturun.fiyati} TL</h4>
                      <h4>{item.sepeturun.ara_toplam} TL</h4>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12">
          <button
            className="btn btn-success btn-sm w-50"
            onClick={() => durumDegistir(data.raf.id, 2)}
          >
            Onayla
          </button>
          <button
            className="btn btn-danger btn-sm w-50"
            onClick={() => durumDegistir(data.raf.id, 3)}
          >
            Reddet
          </button>
          {smsDurum == 0 ? 
          <button
            className="my-2 btn btn-primary btn-sm w-100"
            onClick={() => setSmsDurum(!smsDurum)}
          >
            sms gönder
          </button>
          :
          <>
          <textarea placeholder='Bir mesaj yazın' onChange={(e) => setSmsMesaj((e.target.value).substr(0,144))} value={smsMesaj} className="form-control mt-4"></textarea>
          <button
            className="my-2 btn btn-primary btn-sm w-100"
            onClick={() => smsGonder()}
          >
            gönder
          </button>
          </>
        }
        </div>
      </div>
    </>
  );
}
