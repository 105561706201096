import axios from 'axios';

const API_URL = 'http://127.0.0.1:5683'; 

const getOS = () => {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Win')) return 'Windows';
    if (userAgent.includes('Mac')) return 'MacOS';
    if (userAgent.includes('Linux')) return 'Linux';
    if (userAgent.includes('Android')) return 'Android';
    if (userAgent.includes('like Mac')) return 'iOS';
    return 'Unknown';
  };

export const WritePrinter = async (data,printer) => {
  const os = getOS();
  try {
    if(os == 'Linux'){
        const response = await axios.post(`${API_URL}`,{
            zpl : data
        });
        return response.data;
    }else{
        window.writeToSelectedPrinter(data, printer);
    }
   
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
