import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import Anasayfa from "./components/Anasayfa";
import Sepet from "./components/Sepet";
import Etiket from "./components/etiket";
import SiparisDetay from "./components/SiparisDetay";
import { Layout, Menu, Breadcrumb } from "antd";
import axios from "./components/CustomAxios";
import Girisyap from "./components/Girisyap";
import "./App.css";
import Gecmis from "./components/Gecmis";
import EksikUrunSiparisOnaylama from "./components/EksikUrunSiparisOnaylama";
import EksikUrunSiparisOnaylamaDetay from "./components/EksikUrunSiparisOnaylamaDetay";

const { SubMenu } = Menu;

const { Header, Content, Footer } = Layout;

export default function App() {
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);

  const [isLogin, setIsLogin] = React.useState(false);

  const [breadcrumb, setBreadcrumb] = React.useState("Anasayfa");

  const [userData, setUserData] = React.useState(null);

  const checkLogined = React.useCallback(() => {
    axios
      .post("check")
      .then((res) => {
        if (res.data.status === 1) {
          setIsLogin(true);
          setLoading(false);
          setUserData(res.data.user);
          // if (res.data.user.yetki === 4) {
          //   alert("test");
          // }

          // setTimeout(() => {
          //   history.replace("/eksik-urun-onaylama");
          // }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const logout = () => {
    axios.post("logout").then((res) => {
      if (res.data.status === 1) {
        localStorage.removeItem("token");
        setIsLogin(false);
      }
    });
  };

  React.useEffect(() => {
    checkLogined();
  }, [checkLogined]);

  React.useEffect(() => {
    setBreadcrumb("Anasayfa");
  }, []);

  if (loading) return "Bekleyiniz...";
  if (!isLogin || userData === null) {
    return <Girisyap setIsLogin={setIsLogin} />;
  } else {
    return (
      <Router>
        <Layout className="layout">
          <Header>
            <div className="logo" />
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={["1"]}>
              {userData?.yetki !== 4 && (
                <>
                  <Menu.Item key="1">
                    <Link to="/">Anasayfa</Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="/gecmis">İşlem Geçmişi</Link>
                  </Menu.Item>
                  {userData?.istasyon_id === 4 && (
                    <Menu.Item key="eksik-urun">
                      <Link to="/sepet/1900">Eksik Ürün</Link>
                    </Menu.Item>
                  )}
                </>
              )}

              {userData?.yetki === 4 && (
                <Menu.Item key="eksik-urun-onaylama">
                  <Link to="/eksik-urun-onaylama">
                    Eksik Ürün Sipariş Onaylama
                  </Link>
                </Menu.Item>
              )}

              <SubMenu
                style={{ float: "right" }}
                key="user"
                title={userData?.adi + " " + userData?.soyadi}
              >
                <Menu.Item key="user:logout" onClick={logout}>
                  Çıkış
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Header>
          <Content style={{ padding: "0 50px" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>{breadcrumb}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
              <Switch>
                <Route
                  path="/"
                  render={(props) => (
                    <Anasayfa setBreadcrumb={setBreadcrumb} {...props} />
                  )}
                  exact
                />
                <Route
                  path="/sepet/:id"
                  render={(props) => (
                    <Sepet setBreadcrumb={setBreadcrumb} {...props} />
                  )}
                  exact
                />

                <Route
                  path="/eksik-urun-onaylama"
                  render={(props) => (
                    <EksikUrunSiparisOnaylama
                      setBreadcrumb={setBreadcrumb}
                      {...props}
                    />
                  )}
                  exact
                />

                <Route
                  path="/eksik-urun-onaylama-detay/:id"
                  render={(props) => (
                    <EksikUrunSiparisOnaylamaDetay
                      setBreadcrumb={setBreadcrumb}
                      {...props}
                    />
                  )}
                  exact
                />

                <Route
                  path="/etiket"
                  render={(props) => <Etiket {...props} />}
                  exact
                />
                <Route
                  path="/sepet/detay/:id"
                  render={(props) => (
                    <SiparisDetay
                      userData={userData}
                      setBreadcrumb={setBreadcrumb}
                      {...props}
                    />
                  )}
                  exact
                />
                <Route
                  path="/gecmis"
                  render={(props) => (
                    <Gecmis setBreadcrumb={setBreadcrumb} {...props} />
                  )}
                  exact
                />
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>2021 Modamızbir.Com</Footer>
        </Layout>
      </Router>
    );
  }
}
